<template>
  <v-list three-line>
    <v-list-item class="grey lighten-4">
      <v-list-item-avatar class="mr-2">
        <v-avatar size="28" color="primary">
          <span class="white--text">{{ item.name.substring(0, 2).toUpperCase() }}</span>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.name }} - <span class="caption">{{ item.dateTime | formattedDateTimeMinute }}</span>
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ item.expenseTypeObject.name }} - {{ $t(`hr.expense.payment_type.${item.paymentType}`) }} -
          {{ $helpers.getConvertCurrency(item.requested) }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          {{ item.description }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <div>
          <v-btn :disabled="loading" icon @click="$emit('edit:click')">
            <v-icon color="teal" size="20">mdi-pencil</v-icon>
          </v-btn>
          <v-btn :disabled="loading" icon @click="$emit('remove:click')">
            <v-icon color="red" size="20">mdi-delete</v-icon>
          </v-btn>
        </div>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: "ExpenseItem",
    props: { item: Object, loading: Boolean }
  };
</script>
